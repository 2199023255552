import { ArrowForward } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import {
  BlockTopText,
  Testimonail,
  TopLink,
} from '@renderer-ui-library/molecules'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import Link from 'next/link'
import React, { useMemo } from 'react'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import { ITestimonialsBlockData } from './ITestimonialsBlockData'

export const TestimonialsBlock: React.FC<BlockProps<ITestimonialsBlockData>> =
  React.memo(({ data }) => {
    const { getPrioritizedUrl } = usePrioritizedUrl()
    const topLinkPath = data.button && getPrioritizedUrl(data.button)
    const { isMobile } = useMediaQuery()

    const topLink = useMemo(
      () =>
        !isMobile && data.button ? (
          <TopLink button={data.button} highlight={data.highlight} />
        ) : null,
      [isMobile, data.button, data.highlight]
    )

    return (
      <BlockWrapper blockData={data}>
        {(data.topText || data.topTitle) && (
          <BlockTopText
            title={data.topTitle}
            text={data.topText}
            contentRight={topLink}
            highlight={data.highlight}
          />
        )}

        <Testimonail
          testimonials={data.testimonials}
          highlight={data.highlight}
        />

        {isMobile && topLinkPath && (
          <div>
            <Link href={topLinkPath} passHref prefetch={false}>
              <Button
                noMinWidth
                fullWidth
                size='small'
                color='secondary'
                endIcon={<ArrowForward />}
              >
                {data.button?.text}
              </Button>
            </Link>
          </div>
        )}
      </BlockWrapper>
    )
  })

TestimonialsBlock.displayName = 'TestimonialsBlock'
